.App {
  text-align: center;
	box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.radiobuttonGroup {
	display: flex;
	align-self: flex-start;
	margin-left: 8px;
}

.timer {
	height: 100%;
	width: 100%;
	margin: 0;
	/*padding: 0;*/
	/*display: flex;*/
	/*flex-direction: column;*/
	/*justify-content: center;*/
	/*align-items: center;*/
}

.buttonDiv {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	margin-left: 1rem;
	align-items: center;
	justify-content: space-between;
}

.timer-value {
	font-size: 22em
}

/*.blink-text {*/
	/*animation: blinkText 1.2s infinite;*/
/*}*/

h1, p, span {
	font-size: 8em;
	margin: 0;
	padding: 0;
}

/*@keyframes blinkText {*/
			/*0%{     color: #fff;    }*/
		/*49%{    color: #fff; }*/
    /*60%{    color: transparent; }*/
    /*99%{    color:transparent;  }*/
		/*100%{   color: #fff;    }*/
/*}*/

@media all and (max-device-width: 1200px){
    h1, p, span {
        font-size: 6em;
    }

    .timer-value {
    	font-size: 24em;
    }
}

@media all and (max-device-width: 720px){
    h1, p, span {
        font-size: 3em;
    }

    .timer-value {
    	font-size: 8em;
    }
}

@media all and (max-device-width: 640px){
    h1, p, span {
        font-size: 3em;
    }

    .timer-value {
    	font-size: 8em;
    }
}

@media all and (max-device-width: 320px){
    h1, p, span {
        font-size: 3em;
    }

    .timer-value {
    	font-size: 8em;
    }
}
